var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.canShowTable)?_c('CDataTable',{attrs:{"border":"","hover":"","size":"sm","fields":_vm.tableFields,"items":_vm.tableItems},scopedSlots:_vm._u([{key:"postId",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.postId))])])}},{key:"title",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.title))])])])}},{key:"userLogin",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.userLogin))])])}},{key:"nickname",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.nickname))])])}}],null,false,1592365096)}):_vm._e(),(_vm.notFound)?_c('CAlert',{staticClass:"mt-3 text-center",attrs:{"color":"danger"}},[_vm._v(" 記事はありません。 ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }