<template>
  <!-- 
    画面: 宿記事一覧一覧テーブル
    用途: 宿記事のデータを一覧表示する
   -->
  <div>
    <!-- #region Table -->
    <CDataTable
      border
      hover
      size="sm"
      :fields="tableFields"
      :items="tableItems"
      v-if="canShowTable"
    >
      <td
        slot="postId"
        slot-scope="{ item }"
      >
        <span class="ml-2 text-left">{{ item.postId }}</span>
      </td>
      <td
        slot="title"
        slot-scope="{ item }"
      >
        <a
          :href=item.url
          target="_blank"
        ><span class="ml-2 text-left">{{ item.title }}</span></a>
      </td>
      <td
        slot="userLogin"
        slot-scope="{ item }"
      >
        <span class="ml-2 text-left">{{ item.userLogin }}</span>
      </td>
      <td
        slot="nickname"
        slot-scope="{ item }"
      >
        <span class="ml-2 text-left">{{ item.nickname }}</span>
        <!-- TODO: 投稿者URLのリンクにしたい -->
        <!-- <a
          href=""
          target="_blank"
        ><span class="ml-2 text-left">{{ item.nickname }}</span></a> -->
      </td>
    </CDataTable>
    <!-- #endregion Table -->

    <!-- #region Alert -->
    <CAlert
      color="danger"
      class="mt-3 text-center"
      v-if="notFound"
    >
      記事はありません。
    </CAlert>
    <!-- #endregion Alert -->
  </div>
</template>

<script>
import axios from "axios";
import i18n from "@/i18n";
export default {
  name: "CancelPolicyListTable",

  data() {
    return {
      //#region Table
      // tableItems: [],
      // TODO: 開発用
      tableItems: [
        {
          postId: "59866727",
          title: "「日本三大修験山」で、紅葉と神々に癒される登山はいかが♪",
          url: "https://yado-sagashi.com/59866727/",
          userLogin: "liberty-nanba",
          nickname: "なんば",
        },
        {
          postId: "43079938",
          title: "え！きのこのお刺身！？ 北海道の秋の味覚はじゃがいもだけじゃないかも！",
          url: "https://yado-sagashi.com/43079938/",
          userLogin: "miya-chan",
          nickname: "みやちゃん",
        },
      ],

      /** 一覧列名 */
      tableFields: [
        {
          key: "postId",
          label: `${i18n.t("facilityArticleListPage.postId")}`,
          _classes: "text-center bg-light",
          _style: "width: 10%",
        },
        {
          key: "title",
          label: `${i18n.t("facilityArticleListPage.title")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "userLogin",
          label: `${i18n.t("facilityArticleListPage.userLogin")}`,
          _classes: "text-center bg-light",
          _style: "width: 10%",
        },
        {
          key: "nickname",
          label: `${i18n.t("facilityArticleListPage.nickname")}`,
          _classes: "text-center bg-light",
          _style: "width: 13%",
        },
      ],
      //#endregion Table


      //#region Response data
      response: [],
      //#endregion Response data
    };
  },

  computed: {
    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true; // TODO: 開発用
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.tableItems.length < 1;
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },

  methods: {
    //#region Request
    reqGet() {
      this.loading = true;
      const url = `/rest/hotel/${this.uid}/article`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data;
          console.log(datas);
          this.response = datas;
          this.pourTable(datas.articles);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.PassError = err.response.status;
          this.loading = false;
        });
    },
    //#endregion Request


    //#region Method
    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.tableItems.push({
          postId: data.postId,
          title: data.title,
          url: data.url,
          userLogin: data.userLogin,
          nickname: data.nickname,
        });
      });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>
