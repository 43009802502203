<template>
  <!-- 
    画面: 宿記事一覧
    用途: 宿記事の一覧を参照する
   -->
  <div>

    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="mr-3">{{ $t("facilityArticleListPage.pageTitle") }}</strong>
          </CCardHeader>
          <CCardBody>
            <div class="table">
              <FacilityArticleListTable />
            </div>
          </CCardBody>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

  </div>
</template>

<script>
import FacilityArticleListTable from "./components/ListTable";

export default {
  name: "FacilityArticleList",

  components: {
    FacilityArticleListTable,
  },
}
</script>
